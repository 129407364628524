//import React, {useEffect, useLayoutEffect, useRef, useState} from "react"
import React, {useEffect, useState} from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import {injectIntl} from "react-intl"
import Helmet from "react-helmet"
import {StringParam, withQueryParams} from "use-query-params"
import styled from "styled-components"
import SEO from "../../components/common/layout/seo"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AllFeatures from "../../components/commonV3/features/AllFeatures";
import LayoutWatches from "../../components/common/layout/LayoutWatches";
import FAQSCard from "../../components/commonV3/faqs/FAQSSection";
import IDownload from "../../images/svgs/download.svg"
import ProsegurLogoD from "../../images/svgs/LogoMovistarProsegurD.svg"
import PlansBuyPage from "../../components/homeV3/PlansBuyPage";
import { FormattedMessage } from "gatsby-plugin-intl"
import { BottomSheet } from "react-spring-bottom-sheet"
import 'react-spring-bottom-sheet/dist/style.css'
import BuyFixedBottom from "../../components/homeV3/BuyFixedBottom"


const Compra = ({ location, intl, query }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_buy_from" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_buy_from" })
  const { purchase_method, userId, promotion_code, fpr } = query

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [open, setOpen] = useState(false)
  const openSheet = () => setOpen(true);
  const closeSheet = () => setOpen(false);

  const downloadManual = function () {
    window.dataLayer.push({ "event": "moreinfoClick" });
    const url = intl.formatMessage({ id: "buy_more_info_url" });
    const evtObject = JSON.stringify({
      "name": "openBrowser",
      "url": url,
    })

    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.jsMessageHandler &&
      window.webkit.messageHandlers.jsMessageHandler.postMessage) {
      window.webkit.messageHandlers.jsMessageHandler.postMessage(evtObject)
    } else if (window.JsMessageHandler && window.JsMessageHandler.publish) {
      window.JsMessageHandler.publish(evtObject)
    } else {
      window.open(url, "_blank")
    }
  }

  const desktopFeatures = AllFeatures({intl,layoutTypeCompra:true}); //,  className: "featureDesktopClass"});
  let monthlyPrice = parseFloat(process.env.GATSBY_MONTHLY_PRICE);
  let supermonthlyPrice = parseFloat(process.env.GATSBY_SUPERMONTHLY_PRICE);
  let annualPrice = parseFloat(process.env.GATSBY_ANNUAL_PRICE);
  const  GA_ecommerce =   {
    currency: 'EUR',
    items: [{
      item_id: 'ANNUAL',
      item_name: 'Durcal Watch ANNUAL',
      price: annualPrice,
      currency: 'EUR',
      quantity: 1
    },{
      item_id: 'MONTHLY',
      item_name: 'Durcal Watch MONTHLY',
      price: monthlyPrice,
      currency: 'EUR',
      quantity: 1
    }, {
      item_id: 'SUPERMONTHLY',
      item_name: 'Durcal Watch SUPERMONTHLY',
      price: supermonthlyPrice,
      currency: 'EUR',
      quantity: 1
    }],
    value: monthlyPrice+annualPrice
  }

  useEffect(() => {
    window.dataLayer.push({ "event": "view_item",ecommerce: GA_ecommerce});
  },[]);

  const images = useStaticQuery(graphql`
    query {
      AllPostImages: allFile(
        filter: {
          relativeDirectory: { eq: "slider" }
          extension: { eq: "png" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                originalName
                src
                srcSet
                srcSetWebp
                aspectRatio
                sizes
              }
            }
          }
        }
      },
      img1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "union" }
      ) {
        childImageSharp {
          fluid(maxWidth: 18, quality: 100) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function(i) {
      return (
        <a>
          <StyledImage fluid={images.AllPostImages.edges[i].node
            .childImageSharp.fluid} />
        </a>
      );
    }
  }
  const sliderDotsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
  }


  return (
    <>
      <SEO title={SEOTitle} description={SEODescription} />
      <Helmet>
        <link rel='canonical' href='https://durcal.com/es/reloj/compra'/>
        <script type="text/javascript">{`
          (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
          fpr("init", {cid:"wdxfm0r8"}); 
          fpr("click");
    `}</script>
        <script
          src="https://cdn.firstpromoter.com/fpr.js"
          type="text/javascript"
          async
        ></script>
      </Helmet>
      <LayoutWatches {...{ location }} layoutType="buy">
        <TitleSection>
          <Title>{intl.formatMessage({ id: "buyPageTitle" })}</Title>
          <div className="LongDescription">
            <FormattedMessage
              id="buyPageDesc"
              defaultMessage={intl.formatMessage({ id: "buyPageDescription" })}
              values={{
                strong: chunks => <strong>{chunks}</strong>,
                br: <br />,
              }}
            />
          </div>
          <div className="ProsegurLogo">
            <ProsegurLogoD />
            <div>
              <div className="Title">{intl.formatMessage({ id: "prosegurConnected247" })}</div>
              <div className="Description">{intl.formatMessage({ id: "prosegurConnected247alerts" })}</div>
            </div>
          </div>
        </TitleSection>
        <SelectPlan>{intl.formatMessage({ id: "selectPlan" })}</SelectPlan>
        <BottomSheet
          open={open}
          onDismiss={closeSheet}
          snapPoints={({ maxHeight }) => [
              300
          ]}
        >
          <BottomSheetContent onClose={closeSheet} intl={intl}/>
        </BottomSheet>
        <PlansBuyPage intl={intl} location={location} userId={userId} promotion_code={promotion_code || fpr}
                      purchase_method={purchase_method} icon={images.img1.childImageSharp.fluid}
                      ecommerce={GA_ecommerce} onCLickSeeConditions={openSheet}/>
        <DescriptionFeaturesWrapper>
          <h1>{intl.formatMessage({ id: "homeV3FeaturesSubtitle" })}</h1>
        </DescriptionFeaturesWrapper>
        <GeneralDiv>
          <SliderDiv>
            <SliderWrapper>
              <Slider {...sliderSettings} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                {images.AllPostImages.edges.map(image => (
                  <ImageWrapper key={image.key}>
                    <StyledImage className="sliderrounded" fluid={image.node.childImageSharp.fluid} />
                  </ImageWrapper>
                ))}
              </Slider>
            </SliderWrapper>
            <SliderWrapperDots>
              <Slider {...sliderDotsSettings} asNavFor={nav1} ref={(slider2) => setNav2(slider2)}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </Slider>
            </SliderWrapperDots>
          </SliderDiv>
          <DescriptionDiv>
            <FeaturesSection>
              <FeatureIconsWrapper>
                {desktopFeatures}
              </FeatureIconsWrapper>
            </FeaturesSection>
            <DownloadWrapper>
              <div className="downloaddesc"><a
                onClick={downloadManual}>{intl.formatMessage({ id: "DownloadManual" })}</a></div>
              <div><a onClick={downloadManual}><IDownload /></a></div>
            </DownloadWrapper>
          </DescriptionDiv>
        </GeneralDiv>
        <FAQSCard />
        <BuyFixedBottom  {...{ location, layoutType: "buy" }}/>
      </LayoutWatches>
    </>
  )
}
const BottomSheetContent = ({ onClose,intl }) => {
  return (
    <div className="bottom-sheet-content">
      <button onClick={onClose} className="bottom-sheet-close-button">×</button>
      <div className="title">{intl.formatMessage({ id: "planFooterTitle" })}</div>
      <ul>
        <li>{intl.formatMessage({ id: "planFooterDesc1" })}</li>
      </ul>
    </div>
  );
};

const GeneralDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 32px;
    margin: 24px 16px 62px 16px;

    @media (min-width: ${props => props.theme.screen.md}) {
        flex-direction: row;
        margin: 24px 122px 62px 122px;
    }
`

const SliderDiv = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.screen.md}) {
        width: 50%;
        // margin-top: 40px;
        // margin-left: 64px;
    }
`

const DescriptionDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${props => props.theme.screen.md}) {
        width: 50%;
    }
`

const TitleSection = styled.div`
    text-align: center;
    align-items: center;
    margin-top: 32px;
    @media (min-width: ${props => props.theme.screen.md}) {
        padding: 0 25% 0 25%;
        margin-top: 64px;
    }
    .LongDescription {
        margin: 16px 0 16px 0;
        ${({ theme }) => theme.font_size.regular};
        font-family: ${({ theme }) => theme.font.medium};
        line-height: 22px;
    }
    .ProsegurLogo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        gap: 16px;

        .Title {
            ${({theme}) => theme.font_size.regular};
            font-family: ${({theme}) => theme.font.bold};
        }
        .Description {
            ${({theme}) => theme.font_size.small};
            font-family: ${({theme}) => theme.font.thin};
        }    
    }
`

const FeaturesSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (min-width: ${props => props.theme.screen.md}) {
        //display: none;
    }
`

const FeaturesSectionD = styled.div`
    
        display: flex;
        flex-direction: column;
        order: 4;
        gap: 32px;

        margin: 0 64px 32px 0;
    
`
const Title = styled.div`
    margin: 16px;
    margin-bottom: 8px;
    ${({ theme }) => theme.font_size.xlarge};
    font-family: ${({ theme }) => theme.font.bold};

    @media (min-width: ${props => props.theme.screen.md}) {
        margin: 40px 0 16px 0;
        ${({ theme }) => theme.font_size.xxlarge};
    }
`
const SelectPlan = styled.div`
    margin: 32px 16px 32px 16px;
    text-align: center;
    ${({ theme }) => theme.font_size.larger};
    font-family: ${({ theme }) => theme.font.bold};
    @media (min-width: ${props => props.theme.screen.md}) {
        ${({ theme }) => theme.font_size.xlarge};
        margin: 64px 0 40px 0;
    }
`

const StyledImage = styled(Img)`
    width: 100%;
    display: block;
`
const StyledIcon = styled(Img)`
    position: absolute;
    width: 18px;
    height: 18px;
`
const SliderWrapper = styled.div`
    width: 100%;
    .slick-dots {
        position: relative;
    }
    .slick-dots.slick-thumb li {
        width: 40px;
        height: 100%;
    }
    .slick-dots.slick-thumb li.slick-active {
        outline: 2px solid ${({ theme }) => theme.color.purple};
        contain: content;
    }
    .sliderrounded {border-radius: 16px};
`
const SliderWrapperDots = styled.div`
    padding-bottom: 32px;

    .slick-dots.slick-thumb li {
        width: 10px;
        height: 10px;
        margin: 0 16px;
        border-radius: 999px;
        transition: all 0.5s ease-in-out;
        will-change: width;
        background-color: ${({ theme }) => theme.color.neutral};
    }

    .slick-dots.slick-thumb li.slick-active {
        background-color: ${({ theme }) => theme.color.purple};;
        width: 35px;
    }
`

const ImageWrapper = styled.div`
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    //max-width: 500px;
    @media (max-width: ${props => props.theme.screen.md}) {
        max-width: 800px;
    }
    
    border-radius: 16px;
`

const DownloadWrapper = styled.div`
    @media (min-width: ${props => props.theme.screen.md}) {
        order: 5;
    }

    margin: 16px 0 16px 0;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 24px 16px 16px;
    gap: 24px;

    /* Primary (Purple)/10 */
    background: #FFFFFF;

    /* bakcround blur */
    backdrop-filter: blur(4px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    .downloaddesc {
        ${({ theme }) => theme.font_size.small};
        font-family: ${({ theme }) => theme.font.bold};
        color: ${({ theme }) => theme.color.purple};
        text-decoration: underline;

        @media (min-width: ${props => props.theme.screen.md}) {
            ${({ theme }) => theme.font_size.regular};
        }
    }

    svg {
        width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.color.purple};
    }
`



const DescriptionFeaturesWrapper = styled.div`
    margin: 16px 0 16px 16px;
    text-align: left;

    @media (min-width: ${props => props.theme.screen.md}) {
        margin: 72px 0 0 64px;
        padding-left: 62px;
    }

    h1 {
        ${({theme}) => theme.font_size.larger};
        font-family: ${({theme}) => theme.font.bold};
        margin-bottom: 0;

        @media (min-width: ${props => props.theme.screen.md}) {
            ${({ theme }) => theme.font_size.xlarge};
        }
    }
`
const IncludesWrapper = styled.div`
    @media (min-width: ${props => props.theme.screen.md}) {
        order: 3;
        margin: 0 64px 0 0;
        padding: 32px;

    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    padding: 16px;
    gap: 32px;

    background: #FFFFFF;
    border-radius: 20px;

    flex: none;
    flex-grow: 0;
    .DescriptionWrapper {
        width: 100%;
        ${({ theme }) => theme.font_size.small};
        font-family: ${({ theme }) => theme.font.thin};

    }

    .seemore {
        color: ${({ theme }) => theme.color.purple};
        text-decoration: underline;
        padding-top: 34px;
        text-align: right;
        ${({ theme }) => theme.font_size.regular};
        font-family: ${({ theme }) => theme.font.bold};
    }

    h5 {
        margin: 0;
        ${({ theme }) => theme.font_size.large};
        font-family: ${({ theme }) => theme.font.bold};

        @media (min-width: ${props => props.theme.screen.md}) {
            ${({ theme }) => theme.font_size.xlarge};
        }
    }
`
const IncludesDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 32px;
    width: 100%;
    flex: none;
    align-self: stretch;
    flex-grow: 0;

    .hiddenIcludes {
        display: none;
    }
    
    svg {min-height: 18px;min-width:18px;}

    .hiddenIcludesExpand {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 32px;
        width: 100%;
        flex: none;
        align-self: stretch;
        flex-grow: 0;
    }
`
const Includes = styled.div`
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 8px;

    width: 100%;

    flex: none;
    align-self: stretch;
    flex-grow: 0;
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.thin};

    @media (min-width: ${props => props.theme.screen.md}) {
        ${({ theme }) => theme.font_size.regular};
    }
`


const BuyButtonWrapperD = styled.div`
    display: none;
    @media (min-width: ${props => props.theme.screen.md}) {
        order: 2;
        //max-width: fit-content;
        display: flex;
        margin: 0 64px 32px 0;
        gap: 75px;
        button {
            padding: 30px 30px;
        }
        //display: block;
        padding: 0;
    }
`

const FeatureIconsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    width: 100%;
    gap: 16px;
    justify-content: stretch;

`

export default withQueryParams(
  {
    purchase_method: StringParam,
    userId: StringParam,
    promotion_code: StringParam,
    fpr: StringParam
  },
  injectIntl(Compra),
)
